import React from "react";

export type API = {
    connected: () => boolean;
    windowReady: () => boolean;
    initWindow: (window: Window) => void;
    sendMessage: <T, R>(method: string, msg: T) => Promise<R>;
    handleMessage: <T, R>(method: string, callback: (request: T) => Promise<R>) => void;
}

type Prop = {
    allowedOrigin?: string;
}

type BaseResult<T> = {
    id: string;
    request: string;
    completed: boolean;
    isError: boolean;
    callback?: (json: string) => void;
    result?: T;
    error?: Error;
}

type PendingResult<T> = BaseResult<T> & {
    completed: false;
    isError: false;
    callback: (json: string) => void;
    result?: undefined;
    error?: undefined;
}

type CompletedResult<T> = BaseResult<T> & {
    completed: true;
    isError: false;
    result: T;
    callback?: (json: string) => void;
    error?: undefined;
}

type ErrorResult<T> = BaseResult<T> & {
    completed: true;
    isError: true;
    result?: undefined;
    callback?: (json: string) => void;
    error: Error;
}

type Result<T = unknown> = PendingResult<T> | CompletedResult<T> | ErrorResult<T>;

type CanBeArray<T> = T | Array<T> | T[];

type JsonRpcPayload = {
    id: string | number;
    jsonrpc: "2.0";
}

type JsonRpcRequest<T> = JsonRpcPayload & {
    method: string;
    params: [T];
};

type JsonRpcError = {
    code: number;
    message: string;
    data?: string;
}

type JsonRpcResponse<R = unknown> = JsonRpcPayload & {
    result?: R;
    error?: JsonRpcError;
}

type JsonRpcMessage<T = unknown, R = unknown> = JsonRpcRequest<T> | JsonRpcResponse<R>;

type MessageHandler<T = unknown, R = unknown> = (request: T) => Promise<R>;

const BigIntFormatter = (key: string, value: any) => typeof value === "bigint" ? value.toString() : value

function formatJsonRPC<T>(method: string, val: CanBeArray<T>, id: string) {
    if (Array.isArray(val)) {
        return JSON.stringify({
            jsonrpc: "2.0",
            id,
            method,
            params: [...val]
        }, BigIntFormatter);
    }

    return JSON.stringify({
        jsonrpc: "2.0",
        method,
        id,
        params: [val]
    }, BigIntFormatter);
}

function getRandomInt(max = 1337000) {
    return Math.floor(Math.random() * max);
  }

function isJsonRpcResponse<R>(message: JsonRpcMessage<unknown, R>): message is JsonRpcResponse<R> {
    return "result" in message || "error" in message;
}

function isJsonRpcRequest<T>(message: JsonRpcMessage<T, unknown>): message is JsonRpcRequest<T> {
    return "params" in message && "method" in message;
}

export class JsonRpcJSError extends Error {
    constructor(public code: number, message: string) {
        super(message)
    }
}


export default function useIFrameCommunication({ allowedOrigin }: Prop = {}): API {
    const queryParameters = new URLSearchParams(window.location.search)
    const origin = queryParameters.get("origin") || allowedOrigin;

    if (!origin || origin.trim() === "") {
        throw new Error("No origin given in URI!");
    }

    const connectedRef = React.useRef(false);
    const windowReadyRef = React.useRef(false);
    const messageQueue = React.useRef<Result[]>([]);
    const responseQueue = React.useRef<Map<string, Result>>(new Map());
    const messageHandlers = React.useRef<Map<string, MessageHandler>>(new Map());
    const windowRef = React.useRef<Window | null>(null);

        // Flush queue
    const flushQueue = () => {
        console.log(`Flushing queue with ${messageQueue.current.length} messages`)
        if (messageQueue.current.length > 0) {
            for (const message of messageQueue.current) {
                console.log(`Sending queued message to origin ${origin} with JSON ${message.request}`)
                windowRef.current?.postMessage(message.request)
            }
            messageQueue.current = [];
        }
    }

    const handleMessage = <T, R>(method: string, callback: (request: T) => Promise<R>) => {
        if (messageHandlers.current.has(method)) {
            throw new Error(`Message handler already registered for ${method}`)
        }

        messageHandlers.current.set(method, callback as MessageHandler);
    }

    const onWindowMessage = (event: MessageEvent) => {
        if (event.origin !== origin) {
            console.warn(`Got unexpected message from origin ${event.origin}`);
            return;
        }

        const json = event.data;
        
        console.log(`Got message from ${event.origin} with JSON: ${json}`);

        const readyObj = JSON.parse(json) as { ready: number };

        if (readyObj && readyObj.ready && readyObj.ready >= 1) {
            console.log(`Got ready event from ${origin} with number ${readyObj.ready}`)
            windowReadyRef.current = true;

            if (readyObj.ready < 2) {
                windowRef.current?.postMessage(JSON.stringify({ ready: 3 }), origin);
            } else {
                console.log("Got all 2 ready events");
            }

            // 1 means they have a listener ready
            if (readyObj.ready === 1 || readyObj.ready === 2) {
                flushQueue();
            }
            return;
        }

        const data: JsonRpcMessage = JSON.parse(json);

        const id = data.id.toString();
        if (responseQueue.current.has(id) && isJsonRpcResponse(data)) {
            console.log(`Handling response for ${id}`)
            const r = responseQueue.current.get(id);
            if (!r) {
                console.warn(`Got unexpected message with id ${id}`)
                return;
            }

            if (!r.completed) {
                console.log("Running callback for response")
                r.callback(json);
            } else {
                console.warn("Response already received");
            }
        } else if (isJsonRpcRequest(data)) {
            const method = data.method;
            console.log(`Got request ${method}`)
            if (messageHandlers.current.has(method)) {
                const handler = messageHandlers.current.get(method);
                if (handler) {
                    console.log("Method has handler")
                    handler(data.params[0]).then((response) => {
                        const rpcResponse: JsonRpcResponse = {
                            id,
                            jsonrpc: "2.0",
                            result: response
                        }

                        return rpcResponse
                    }).catch((err: JsonRpcJSError) => {
                        const rpcResponse: JsonRpcResponse = {
                            id,
                            jsonrpc: "2.0",
                            error: {
                                code: err.code,
                                message: err.message
                            }
                        }

                        return rpcResponse
                    }).then((response) => {
                        const json = JSON.stringify(response);

                        console.log(`Sending response for id ${id} to origin ${origin} with JSON ${json}`)
                        windowRef.current?.postMessage(json, origin);
                    });
                } else {
                    console.warn(`Handler for ${method} is null`)
                }
            } else {
                console.warn(`No handler for ${method}`)
            }
        } else {
            console.warn(`Cannot determine type of JSON RPC ${json}`)
        }
    };

    const sendMessageRaw = <T, R>(method: string, val: CanBeArray<T>): Promise<R> => {
        let id: string;
        do {
            id = getRandomInt().toString();
        } while (responseQueue.current.has(id));

        const json = formatJsonRPC(method, val, id);

        return new Promise<R>((resolve, reject) => {
            const message = {
                completed: false,
                isError: false,
                request: json,
                id,
                callback: (json: string) => {
                    const data: JsonRpcResponse<R> = JSON.parse(json);
                    let result: Result<R>;
                    if (data.error) {
                        result = {
                            id,
                            isError: true,
                            completed: true,
                            request: json,
                            error: new Error(`Response errored with code ${data.error.code}: ${data.error.message}`)
                        }
                    } else if (data.result) {
                        result = {
                            id,
                            completed: true,
                            isError: false,
                            request: json,
                            result: data.result,
                        }
                    } else {
                        console.warn(`Got empty response with id ${id}: ${json}`);
                        return;
                    }

                    if (result.isError) {
                        reject(result.error);
                    } else {
                        resolve(result.result);
                    }

                    responseQueue.current.set(id, result);
                }
            } as Result<R>;

            responseQueue.current.set(id, message);
    
            if (!windowReadyRef.current) {
                console.log(`Queuing message with id ${id} to origin ${origin} with JSON ${json}`)
                messageQueue.current.push(message);
            } else {
                console.log(`Sending message with id ${id} to origin ${origin} with JSON ${json}`)
                windowRef.current?.postMessage(json, origin);
            }
        });
    }

    const sendMessage = sendMessageRaw

    const initWindow = (newWin: Window) => {
        if (newWin === null) return;

        windowReadyRef.current = false;
        console.log(`Running init with Window (connected? ${connectedRef.current})`)
        windowRef.current = newWin;

        if (connectedRef.current) {
            newWin.postMessage(JSON.stringify({ ready: 2 }), origin);
            console.log(`Sent ready message to origin ${origin}`)
        }

    }

    // Setup listeners
    React.useEffect(() => {
        if (connectedRef.current) return;

        console.log(`Registering listener (has window ref? ${windowRef.current !== null})`)
        window.addEventListener("message", onWindowMessage);
        connectedRef.current = true

        if (windowRef.current) {
            windowRef.current.postMessage(JSON.stringify({ ready: 1 }), origin);
            console.log(`Sent ready message to origin ${origin}`)
        }
    });

    return {
        connected: () => connectedRef.current,
        windowReady: () => windowReadyRef.current,
        sendMessage,
        handleMessage,
        initWindow,
    }
}