import React from 'react';
import Button from '@mui/material/Button';
import './App.css';
import { sepolia as chain, sepolia } from "viem/chains";
import {
  CaveatStruct,
  createExecution,
  createOpenRootDelegation,
  DelegationStruct,
  type DeleGatorClient,
  Hex,
  UserOperationReceiptResponse,
} from "@codefi/delegator-core-viem";
import { allGatorPasskeyAccounts, GatorWebAuthnAccount, hasGatorPasskeyAccounts, loadGatorPasskeyAccount, newGatorPasskeyAccount } from '../../gator';
import { alpha, Divider, MenuItem, OutlinedInputProps, Select, styled, TextField, TextFieldProps, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { deflate, inflate } from 'pako';
import Swal from 'sweetalert2';
import './index.css';
import { BaseWindowClient, wrapWindowAsTransport } from '../../comms';
import { Address } from 'viem';
import { MetaMaskPortfolioWalletContext } from '../../api/CrossDomainWalletContextProvider';

export type SignerTypes = "account" | "wallet" | (string & {});

type BasePermissionRequest = {
  chainId: Hex; // hex-encoding of uint256
  address?: Address;
  expiry: number; // unix timestamp
  signer: {
      type: SignerTypes; // enum defined by ERCs
      data: Record<string, any>;
  };
  permission: {
      type: string; // enum defined by ERCs
      data: Record<string, any>;
  };
  policies: {
      type: string; // enum defined by ERCs
      data: Record<string, any>;
  }[];
}

export type AccountPermissionRequest = BasePermissionRequest & {
  signer: {
      type: "account",
      data: {
          address: Hex;
      }
  }
}

export type WalletPermissionRequest = BasePermissionRequest & {
  signer: {
      type: "wallet",
      data: {}
  }
};

export type PermissionRequest = AccountPermissionRequest | WalletPermissionRequest | BasePermissionRequest;

export type PermissionResponse = BasePermissionRequest & {
  context: Hex;
  accountMeta?: {
    factory: Hex;
    factoryData: Hex;
  };
  signerMeta?: {
    // 7679 userOp building
    userOpBuilder?: Hex;
    // 7710 delegation
    delegationManager?: Hex;
  };
};

const StyledContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
`;

function LoginOrCreate() {
  const { remoteLogin } = React.useContext(MetaMaskPortfolioWalletContext);

  return (
    <StyledContainer>
      <p>
        Example dApp
      </p>

      <h3>
        Connect Wallet
      </h3>
      <Divider />
      <Button variant="contained" onClick={remoteLogin}>Login via MetaMask Portfolio</Button>
      
    </StyledContainer>
  )
}

function SendTransactionDemo() {
  const { connectedAddress, sendWallet } = React.useContext(MetaMaskPortfolioWalletContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const sendDemoTransaction = React.useCallback(() => {
    setIsLoading(true);
    try {
      sendWallet();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [connectedAddress, setIsLoading, sendWallet]);

  return (
    <StyledContainer>
      <p>Connected Address: {connectedAddress}</p>
      <Divider />

      <LoadingButton loading={isLoading} variant='contained' onClick={sendDemoTransaction}>Send Demo Transaction</LoadingButton>
    </StyledContainer>
  )
}

function App() {
  const { isConnected } = React.useContext(MetaMaskPortfolioWalletContext);

  return (
    <div className="App">
      <header className="App-header">
          <div className='btnGroup'>
          {
            !isConnected ? 
              (
                <LoginOrCreate />
              ) :
              (
                <SendTransactionDemo />
              )
          }
        </div>
      </header>
    </div>
  );
}

export default App;
