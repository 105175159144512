import { DeleGatorClient } from "@codefi/delegator-core-viem";
import { Button } from "@mui/material";
import React from "react";
import { GatorWebAuthnAccount, newGatorPasskeyAccount } from "../../gator";
import { sepolia as chain } from "viem/chains";
import Swal from "sweetalert2";

export default function CreateWallet() {
    const [delegator, setDelegator] = React.useState<DeleGatorClient>();
    const [account, setAccount] = React.useState<GatorWebAuthnAccount>();
    const [isError, setIsError] = React.useState(false);

    const createWallet = React.useCallback(async () => {
        try {
            const accountName = "";
        
            setIsError(false);
        
            const gatorAccount = await newGatorPasskeyAccount({
            walletName: accountName,
            rp: "localhost",
            chain,
            });
        
            setDelegator(gatorAccount.delegator);
            setAccount(gatorAccount);
        } catch (e) {
            console.error(e);
            const errJson = JSON.stringify(e, Object.getOwnPropertyNames(e));
            await Swal.fire("Error", `There was an error during wallet creation:\n${errJson}\nPlease check the console for more details.`, "error");
        }
      }, [setDelegator, setAccount, setIsError]);

    return (
        <Button variant="contained" onClick={createWallet}>Create New Wallet</Button>
    )
}