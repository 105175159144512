import { createDeleGatorClient, DeleGatorClient, ExecutionStruct, Hex, Implementation, Signatory, SIGNATURE_ABI_PARAMS, SmartAccountConfig } from "@codefi/delegator-core-viem";
import { Hash, SignableMessage, TypedData, TypedDataDefinition, zeroAddress, encodeAbiParameters, hashMessage, hashTypedData, Chain, http, keccak256, encodePacked, concat, HttpTransport } from "viem";
import { toWebAuthnAccount } from "viem/account-abstraction";
import { parseSignature, WebAuthnData, bytesToBase64Url, hexToBytes } from "webauthn-p256";
import { GatorCredential, GatorWebAuthnAccount, PasskeyCredentialWithName, PasskeyStorage } from "./types";
import { getPublicKeyXY, splitClientDataJSON } from "./utils";
import { createClient } from "viem";
import { sepolia as chain } from "viem/chains";
import {
  PimlicoGasFeeResolver,
  createBundlerClient,
  createExecution,
  UserOperationV07,
} from "@codefi/delegator-core-viem";

import {
  pimlicoPaymasterActions,
} from "permissionless/actions/pimlico";
import { ENTRYPOINT_ADDRESS_V07 } from 'permissionless';

const BUNDLER_URL = process.env.NEXT_PUBLIC_BUNDLER_URL || "https://api.pimlico.io/v2/sepolia/rpc?apikey=pim_fmSYitotvWxpbcaJyCdsgF";
const PIMLICO_PAYMASTER_KEY = process.env.NEXT_PUBLIC_PAYMASTER_API_KEY || "pim_fmSYitotvWxpbcaJyCdsgF";
const PIMLICO_PAYMASTER_URL = `https://api.pimlico.io/v2/${chain.id}/rpc?apikey=${PIMLICO_PAYMASTER_KEY}`

export async function buildGatorAccountFromPasskeyCredentials(credential: PasskeyCredentialWithName | GatorCredential, rp: string, chain: Chain, storage: PasskeyStorage, authenticate: boolean = false): Promise<GatorWebAuthnAccount> {
    const keyId = credential.id;

    const gasFeeResolver = PimlicoGasFeeResolver({
        pimlicoAPIKey: PIMLICO_PAYMASTER_KEY,
        inclusionSpeed: "fast",
    });

    const paymaster = createClient({
        transport: http(PIMLICO_PAYMASTER_URL),
        chain
    }).extend(pimlicoPaymasterActions(ENTRYPOINT_ADDRESS_V07))

    const account = await toWebAuthnAccount({
        credential,
        rpId: rp,
    });

    if (authenticate) {
        await account.sign({ hash: "0x1234" });
    }

    const formatPasskeySignature = (data: WebAuthnData, hash: Hex, signature: Hex): Hex => {
        const keyIdHash = keccak256(encodePacked(['string'], [keyId]));

        const challenge = bytesToBase64Url(hexToBytes(hash));
    
        const { r, s } = parseSignature(signature);
    
        const { prefix, suffix } = splitClientDataJSON(data, challenge);
    
        const formattedSig = encodeAbiParameters(SIGNATURE_ABI_PARAMS,
            [keyIdHash, r, s, data.authenticatorData, data.userVerificationRequired, prefix, suffix, BigInt(data.typeIndex)]);
    
        return formattedSig;
    }

    const createDummySignature = () => {
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Authentication_API/Authenticator_data#data_structure
        const rpIdHash = keccak256(encodePacked(['string'], ['AuthenticatorData']));
        const flags = '0x05';
        const signCount = '0x00000000';
        const authenticatorData = concat([rpIdHash, flags, signCount]);

        const keyIdHash = keccak256(encodePacked(['string'], [keyId]));
        const rs = BigInt("57896044605178124381348723474703786764998477612067880171211129530534256022184")
        const userVerification = true;
        const clientDataPrefix = '{"type":"webauthn.get","challenge":"';
        const clientDataSuffix = '","origin":"passkey-domain","crossOrigin":false}';
        const responseTypeLocation = BigInt(1);

        const encodedSignature = encodeAbiParameters(SIGNATURE_ABI_PARAMS, [
            keyIdHash,
            rs,
            rs,
            authenticatorData,
            userVerification,
            clientDataPrefix,
            clientDataSuffix,
            responseTypeLocation,
        ]);

        return encodedSignature;
    };

    const signatory: Signatory = {
        sign: async (parameters: { hash: Hash; }): Promise<Hex> => {
            const result = await account.sign({ hash: parameters.hash });

            return result.signature;
        },
        signMessage: async ({ message }: { message: SignableMessage }): Promise<Hex> => {
            const result = await account.signMessage({ message });

            return formatPasskeySignature(result.webauthn, hashMessage(message), result.signature);
        },
        signTypedData: async <const typedData extends TypedData | Record<string, unknown>, primaryType extends keyof typedData | "EIP712Domain" = keyof typedData>(parameters: TypedDataDefinition<typedData, primaryType>): Promise<Hex> => {
            const result = await account.signTypedData(parameters);

            return formatPasskeySignature(result.webauthn, hashTypedData(parameters), result.signature);
        }
    }

    const { publicKeyX, publicKeyY } = getPublicKeyXY(account.publicKey);

    const isDeployed = "isDeployed" in credential && credential.isDeployed && credential.address !== undefined;

    const delegatorClient = isDeployed && credential.address ?
        createDeleGatorClient({
            transport: http(),
            chain,
            account: {
                implementation: Implementation.Hybrid,
                isAccountDeployed: true,
                address: credential.address,
                signatory,
            },
        }) :
        createDeleGatorClient({
            transport: http(),
            chain,
            account: {
                implementation: Implementation.Hybrid,
                isAccountDeployed: false,
                deploySalt: "0x1",
                deployParams: [zeroAddress, [keyId], [publicKeyX], [publicKeyY]],
                signatory,
            },
        });
    
    const sponsorAndSendUserOperation = async (unsponsoredUserOp: UserOperationV07) => {
        const bundler = createBundlerClient(BUNDLER_URL);

        unsponsoredUserOp.signature = createDummySignature();
        
        const sponsorship = await paymaster.sponsorUserOperation({
            userOperation: unsponsoredUserOp
        });

        const unsignedUserOp: UserOperationV07 = {
            ...unsponsoredUserOp,
            ...sponsorship,
        };

        const userOp = await delegatorClient.signUserOp(unsignedUserOp);

        const { result: hash } = await bundler.sendUserOp(
            userOp,
            delegatorClient.account.environment.EntryPoint
        );

        const { result } = await bundler.pollForReceipt(hash);

        return result;
    }

    const signAndSendAction = async (action: ExecutionStruct) => {
        const unsponsoredUserOp = await delegatorClient.createExecuteUserOp(
            action,
            await gasFeeResolver.determineGasFee(chain)
        );

        return await sponsorAndSendUserOperation(unsponsoredUserOp);
    };

    storage.writeData<GatorCredential>(credential.walletName, {
        ...credential,
        address: delegatorClient.account.address,
        isDeployed,
    });
    
    return {
        credential: {
            ...credential,
            get address(): Hex { return delegatorClient.account.address },
            get isDeployed(): boolean { return delegatorClient.account.isAccountDeployed },
        },
        webauthn: account,
        keyId,
        signer: signatory,
        delegator: delegatorClient,
        /**
         * Creates a dummy signature.
         *
         * This must meet all early-failure conditions of the real signature, but does not need to be a valid signature.
         *
         * See the tests for each of the early-failure conditions.
         *
         * @returns The encoded signature.
         */
        createDummySignature,
        signAndSendAction,
        sponsorAndSendUserOperation,
        deploy: async () => {
            const action = createExecution("0x0000000000000000000000000000000000000000");

            const result = await signAndSendAction(action);

            if (!result.success) {
                throw new Error(`UserOperation failed: ${result.reason}`);
            }

            storage.writeData<GatorCredential>(credential.walletName, {
                ...credential,
                address: delegatorClient.account.address,
                isDeployed: true
            });

            return result;
        },
        gasFees: () => {
            return gasFeeResolver.determineGasFee(chain);
        }
    }
}