export type ListenerDeconstructor = () => void;

export type TransportClient = {
    readonly origin: string;
    sendMessage(msg: string): void;
    listenForMessages(onMessage: (evt: MessageEvent) => void): ListenerDeconstructor;
}

export function wrapWindowAsTransport(window: Window, targetOrigin?: string) {
    return new WindowTransport(window, targetOrigin);
}

class WindowTransport implements TransportClient {
    private onMessage?: (evt: MessageEvent) => void;

    constructor(private _winHandle: Window, private targetOrigin?: string) {
        try {
            if (window !== undefined && (_winHandle.parent === window || _winHandle.opener === window))  {
                console.log(`[ ${window.origin} ]  ` +"This window made the handle given, lets trust it")
                return;
            }

            // validate
            if (_winHandle.origin !== targetOrigin) {
                throw new Error("The window origin and target origin do not match");
            }
        } catch (e: any) {
            try {
                // we likely can't read this
                // get the origin another way
                const origins = window.location.ancestorOrigins;
                if (origins.length !== 1) {
                    throw new Error(`Unexpected iFrame window.location.ancestorOrigins, got ${JSON.stringify(window.location.ancestorOrigins)}`)
                }

                const parentOrigin = origins[0];

                if (parentOrigin !== targetOrigin) {
                    throw new Error("The window origin and target origin do not match");
                } else {
                    console.warn(`Could not read window handle origin, but verified ancestorOrigins matches target`);
                    return;
                }
            } catch (err) {
                console.error(`Couldn't verify target origin ${targetOrigin} matches window handle given`)
                console.error(err);
            }
        }
    }

    get origin(): string {
        try {
            return this._winHandle.origin;
        } catch {
            return this.targetOrigin ?? "??";
        }
    }
    sendMessage(msg: string): void {
        console.log(`[ ${window.origin} ]   ` +`Doing postMessage with ${msg} to ${this.origin}`)
        this._winHandle.postMessage(msg, this.origin);
    }

    private onHandleMessage(msg: MessageEvent) {
        console.log(`[ ${window.origin} ]   ` +`Got message ${JSON.stringify(msg)}`)
        if (msg.origin !== this.origin) {
            console.log(`[ ${window.origin} ]   ` +`Origin doesn't match ${msg.origin} !== ${this.origin}`)
            // TODO Log warning
            return;
        }

        if (!this.onMessage) {
            // TODO Log warning
            console.log(`[ ${window.origin} ]   ` +"No listner setup")
            return;
        }

        console.log(`[ ${window.origin} ]   ` +"Trigger listner")
        this.onMessage(msg);
    }

    listenForMessages(onMessage: (evt: MessageEvent) => void): ListenerDeconstructor {
        if (this.onMessage) {
            throw new Error("Already listening to events");
        }

        console.log(`[ ${window.origin} ]   ` +`Setting up listener to listen to ${this.origin}`)
        this.onMessage = onMessage;
        const listner = (msg: MessageEvent) => this.onHandleMessage(msg);
        window.addEventListener("message", listner);

        return () => {
            window.removeEventListener("message", listner);
        }
    }

}