import { WebAuthnData } from "webauthn-p256";

export function getPublicKeyXY(hexPublicKey: string) {
    // Remove the '04' prefix (first byte)
    const xHex = hexPublicKey.slice(2, 66);  // Characters 2 to 66 (32 bytes)
    const yHex = hexPublicKey.slice(66);     // Characters 66 to the end (32 bytes)

    return {
        publicKeyX: BigInt(`0x${xHex}`),
        publicKeyY: BigInt(`0x${yHex}`)
    };
}

export function splitClientDataJSON(webAuthnData: WebAuthnData, challenge: string): { prefix: string; suffix: string } {
    const { clientDataJSON } = webAuthnData;

    // Define the challenge field in the clientDataJSON
    const challengeField = `"challenge":"${challenge}"`;

    // Find where the challenge occurs in the clientDataJSON
    const challengeIndex = clientDataJSON.indexOf(challengeField);

    if (challengeIndex === -1) {
        throw new Error("Challenge not found in clientDataJSON");
    }

    // Slice the clientDataJSON into prefix and suffix
    const prefix = clientDataJSON.slice(0, challengeIndex + `"challenge":"`.length);
    const suffix = clientDataJSON.slice(challengeIndex + challengeField.length - 1);

    // Return the prefix and suffix
    return { prefix, suffix };
}   