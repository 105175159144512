export type WindowReadyState = {
    readyForMessages: boolean;
    heardFromPeer: boolean;
    enabledPolicies: string[];
    isNewSession: boolean;
}

export function isReadyStateMessage(obj: unknown): obj is WindowReadyState {
    return obj !== null && 
        (typeof obj === "object") && 
        "readyForMessages" in obj && 
        "enabledPolicies" in obj && 
        "heardFromPeer" in obj &&
        "isNewSession" in obj;
}