import { WindowReadyState, isReadyStateMessage } from "./messages";
import { ListenerDeconstructor, TransportClient } from "./transport";
import { Result, MessageHandler, JsonRpcMessage, JsonRpcResponse, CanBeArray } from "./types";
import { isJsonRpcResponse, isJsonRpcRequest, JsonRpcJSError, getRandomInt, formatJsonRPC } from "./utils";

type ConnectionOptions = {
    transport?: TransportClient
}

export class BaseWindowClient {
    private peer: TransportClient | null = null;
    private peerReadyState: WindowReadyState | null = null;
    private eventTeardown: ListenerDeconstructor | null = null;

    private messageQueue: string[] = [];
    private responseQueue: Map<string, Result> = new Map();
    private messageHandlers: Map<string, MessageHandler> = new Map();
    private eventsSetup: boolean = false;

    constructor({ transport }: ConnectionOptions) {
        if (transport) {
            this.attachTransport(transport);
        }
    }

    private flushQueue() {
        console.log(`[ ${window.origin} ] ` +`Flushing queue with ${this.messageQueue.length} messages`)
        if (this.messageQueue.length > 0 && this.peer && this.connected) {
            for (const message of this.messageQueue) {
                console.log(`[ ${window.origin} ] ` +`Sending queued message to origin ${this.peer.origin} with JSON ${message}`)
                this.peer.sendMessage(message)
            }
            this.messageQueue = [];
        }
    }

    public handleMessage<T, R>(method: string, callback: (request: T) => Promise<R>): ListenerDeconstructor {
        if (this.messageHandlers.has(method)) {
            throw new Error(`Message handler already registered for ${method}`)
        }

        this.messageHandlers.set(method, callback as MessageHandler);

        return () => {
            this.messageHandlers.delete(method);
        }
    }

    private onWindowMessage(event: MessageEvent) {
        const json = event.data;

        if (typeof json !== "string") {
            console.log("Got non json string, skipping");
            return;
        }
        
        console.log(`[ ${window.origin} ] ` +`Got message from ${event.origin} with JSON: ${json}`);

        const readyObj = JSON.parse(json);

        if (isReadyStateMessage(readyObj)) {
            console.log(`[ ${window.origin} ] ` +`Got ready event from ${this.peer?.origin} with readiness ${readyObj.readyForMessages}`)
            this.peerReadyState = readyObj;

            if (this.connected && this.peer) {
                this.flushQueue();
            }

            if (!this.peerReadyState.heardFromPeer) {
                const json = JSON.stringify({
                    readyForMessages: true,
                    enabledPolicies: [],
                    isNewSession: false,
                    heardFromPeer: true,
                } as WindowReadyState)

                this.sendWindowMessage(json);
            }
            return;
        }

        const data: JsonRpcMessage = JSON.parse(json);

        const id = data.id.toString();
        if (this.responseQueue.has(id) && isJsonRpcResponse(data)) {
            console.log(`[ ${window.origin} ] ` +`Handling response for ${id}`)
            const r = this.responseQueue.get(id);
            if (!r) {
                console.warn(`Got unexpected message with id ${id}`)
                return;
            }

            if (!r.completed) {
                console.log(`[ ${window.origin} ] ` +"Running callback for response")
                r.callback(json);
            } else {
                console.warn("Response already received");
            }
        } else if (isJsonRpcRequest(data)) {
            const method = data.method;
            console.log(`[ ${window.origin} ] ` +`Got request ${method}`)
            if (this.messageHandlers.has(method)) {
                const handler = this.messageHandlers.get(method);
                if (handler) {
                    console.log(`[ ${window.origin} ] ` +"Method has handler")
                    handler(data.params[0]).then((response) => {
                        const rpcResponse: JsonRpcResponse = {
                            id,
                            jsonrpc: "2.0",
                            result: response
                        }

                        return rpcResponse
                    }).catch((err: JsonRpcJSError) => {
                        const rpcResponse: JsonRpcResponse = {
                            id,
                            jsonrpc: "2.0",
                            error: {
                                code: err.code,
                                message: err.message
                            }
                        }

                        return rpcResponse
                    }).then((response) => {
                        const json = JSON.stringify(response);

                        this.sendWindowMessage(json);
                    });
                } else {
                    console.warn(`Handler for ${method} is null`)
                }
            } else {
                console.warn(`No handler for ${method}`)
            }
        } else {
            console.warn(`Cannot determine type of JSON RPC ${json}`)
        }
    }

    private sendWindowMessage(json: string): boolean {
        if (!this.peer || !this.connected) {
            console.log(`[ ${window.origin} ] ` +`Queued message ${json}`)
            this.messageQueue.push(json);
            return false;
        } else {
            console.log(`[ ${window.origin} ] ` +`Sending message thru transport ${json}`)
            this.peer.sendMessage(json);
            return true;
        }
    }

    public sendMessage<T, R>(method: string, val: CanBeArray<T>): Promise<R> {
        let id: string;
        do {
            id = getRandomInt().toString();
        } while (this.responseQueue.has(id));

        const json = formatJsonRPC(method, val, id);

        return new Promise<R>((resolve, reject) => {
            const message = {
                completed: false,
                isError: false,
                request: json,
                id,
                callback: (json: string) => {
                    const data: JsonRpcResponse<R> = JSON.parse(json);
                    let result: Result<R>;
                    if (data.error) {
                        result = {
                            id,
                            isError: true,
                            completed: true,
                            request: json,
                            error: new Error(`Response errored with code ${data.error.code}: ${data.error.message}`)
                        }
                    } else if (data.result) {
                        result = {
                            id,
                            completed: true,
                            isError: false,
                            request: json,
                            result: data.result,
                        }
                    } else {
                        console.warn(`Got empty response with id ${id}: ${json}`);
                        return;
                    }

                    this.responseQueue.set(id, result);

                    if (result.isError) {
                        reject(result.error);
                    } else {
                        resolve(result.result);
                    }
                }
            } as Result<R>;

            this.responseQueue.set(id, message);
    
            this.sendWindowMessage(json);
        });
    }

    private setupEvents() {
        if (this.eventsSetup || !this.peer) return;
        
        console.log(`[ ${window.origin} ] ` +"Adding listner")

        const listner = (msg: MessageEvent) => this.onWindowMessage(msg);

        this.eventTeardown = this.peer.listenForMessages(listner);

        // TODO Handle other window events like closing, refresh and such

        this.eventsSetup = true;
    }

    private teardownEvents() {
        if (!this.eventsSetup || !this.eventTeardown) return;

        this.eventTeardown();
        
        // TODO Handle other window events like closing, refresh and such

        this.eventTeardown = null;
        this.eventsSetup = false;
    }

    public attachTransport(target: TransportClient) {
        if (this.peer) {
            throw new Error("You must detach window first");
        }

        console.log(`[ ${window.origin} ] ` +`Attaching transport ${target.origin}`)
        this.peerReadyState = null;
        this.peer = target;

        this.setupEvents();
        const json = JSON.stringify({
            readyForMessages: true,
            enabledPolicies: [],
            isNewSession: false,
            heardFromPeer: false,
        } as WindowReadyState)

        console.log(`[ ${window.origin} ] ` +`Sending ready message ${json}`)

        this.peer.sendMessage(json);
    }

    public detachWindow() {
        if (!this.peer) {
            throw new Error("You must attach window first");
        }

        const json = JSON.stringify({
            readyForMessages: false,
            enabledPolicies: [],
            isNewSession: false,
            heardFromPeer: true,
        } as WindowReadyState)

        this.sendWindowMessage(json);

        this.teardownEvents();
        
        this.peer = null;
        this.peerReadyState = null;
    }

    public get connected() {
        return (this.peer && this.peerReadyState?.readyForMessages)
    }
}