import { createWebAuthnCredential } from "viem/account-abstraction";
import { buildGatorAccountFromPasskeyCredentials } from "./gator";
import { buildLocalStorage } from "./storage";
import { GatorCredential, GatorPasskeyOptions, GatorWebAuthnAccount, PasskeyCredential } from "./types";
export * from "./types";

export const DefaultStorage = buildLocalStorage();

export async function loadGatorPasskeyAccount({ walletName, rp, chain, storage = DefaultStorage }: GatorPasskeyOptions): Promise<GatorWebAuthnAccount> {
    const credential = await storage.load<GatorCredential>(walletName, null);

    if (!credential) {
        throw new Error(`No such account ${walletName}`);
    }

    return await buildGatorAccountFromPasskeyCredentials({
        ...credential,
        walletName
    }, rp, chain, storage, true);
}

export async function newGatorPasskeyAccount({ walletName, rp, chain, storage = DefaultStorage }: GatorPasskeyOptions): Promise<GatorWebAuthnAccount> {
    const currentWalletData = await storage.load<GatorCredential>(walletName, null)
    if (currentWalletData) {
        storage.writeData(walletName, null);
    }

    const credential = await createWebAuthnCredential({
        name: walletName
    });

    return await buildGatorAccountFromPasskeyCredentials({
        ...credential,
        walletName
    }, rp, chain, storage);
}

export function hasGatorPasskeyAccounts(storage = DefaultStorage) {
    const keys = storage.allKeys();

    return keys.length > 0;
}

export function allGatorPasskeyAccounts(storage = DefaultStorage) {
    return storage.allKeys();
}