import { styled } from "@mui/material";
import React from "react";
import { wrapWindowAsTransport, BaseWindowClient } from "../comms";
import Swal from "sweetalert2";
import { sepolia } from "viem/chains";
import { PermissionResponse, WalletPermissionRequest } from "../pages/root/App";
import { Hex, UserOperationReceiptResponse } from "@codefi/delegator-core-viem";

type SendTx = {
    to: Hex;
    data: Hex;
    value: bigint;
}

type MetaMaskPortfolioWalletContext = {
    remoteLogin: () => void;
    sendWallet: () => void;
    isConnected: boolean;
    connectedAddress: string | null;
}

const DefaultContext: MetaMaskPortfolioWalletContext = { 
    remoteLogin() { },
    sendWallet() { },
    isConnected: false,
    connectedAddress: null,
}

export const MetaMaskPortfolioWalletContext = React.createContext(DefaultContext);

const BaseIFrameUrl = 'https://gator.edkek.com/iframe'
const IFrameUrl = (o: string) => `${BaseIFrameUrl}?origin=${o}`

const StyledIFrame = styled("iframe")`
  border: none;
  display: none;
`;

export default function MetaMaskPortfolioWalletContextProvider({ children }: React.PropsWithChildren) {
    const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
    const iframeClientRef = React.useRef<BaseWindowClient | null>(null);
    const popupClientRef = React.useRef<BaseWindowClient | null>(null);
    const [connectedAddress, setConnectedAddress] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (iframeRef.current !== null && iframeRef.current.contentWindow !== null) {
            const interval = setInterval(() => {
            if (iframeClientRef.current) {
                clearInterval(interval);
                console.log("Skipping interval check");
                return;
            }

            if (iframeRef.current !== null && iframeRef.current.contentWindow !== null) {
                try {
                console.log(`Checking on ${iframeRef.current.contentWindow.origin}`)
                return;
                } catch {
                console.log("Seems cross origin is in effect, setting up transport");
                const transport = wrapWindowAsTransport(iframeRef.current.contentWindow, "https://gator.edkek.com");
                iframeClientRef.current = new BaseWindowClient({
                    transport
                });
                clearInterval(interval);
                }
            }
            }, 1000);

        }
    });

    const sendWallet = React.useCallback(async () => {
        if (!connectedAddress) return;

        const request: SendTx = {
            to: "0x0",
            value: BigInt(0),
            data: "0x0"
        }
        const url = `https://gator.edkek.com/popup?origin=${window.location.origin}&action=tx`; // URL to open in the popup
        const windowName = 'Gator Wallet'; // Name of the popup window
        const windowFeatures = 'width=357,height=600'; // Size of the popup window

        const win = window.open(url, windowName, windowFeatures);
        if (win) {
            const checkPopupWindow = () => {
              try {
                console.log(`Checking popup ${win.origin}`);
                setTimeout(checkPopupWindow, 100);
                return;
              } catch {
                console.log(`Seems cross origin is active`)
                const transport = wrapWindowAsTransport(win, "https://gator.edkek.com");
                const popup = new BaseWindowClient({ transport });
  
                setTimeout(async () => {
                  const response = await popup.sendMessage<SendTx, UserOperationReceiptResponse>("testSend", request)
                  console.log("Got response")
                  console.log(JSON.stringify(response))
                  if (response.success) {
                    await Swal.fire("Success", `Transaction successfully sent\nhttps://sepolia.etherscan.io/tx/${response.receipt.transactionHash}`, "success");
                  }
                  win.close();
                })
              }
            };
  
            setTimeout(checkPopupWindow, 100);
            return;
          } else {
            await Swal.fire("Could not open popup", "Please allow pop ups", "warning");
            return;
          }
    }, [connectedAddress]);

    const remoteLogin = React.useCallback(async () => {
        let client = iframeClientRef.current;
        if (!client) return;
    
        const request: WalletPermissionRequest = {
          signer: {
            type: "wallet",
            data: {}
          },
          chainId: `0x${sepolia.id.toString(16)}`,
          expiry: Date.now() + (600 * 60_000),
          permission: {
            type: "native-token-transfer",
            data: {
              allowance: `0x${Number.MAX_SAFE_INTEGER.toString(16)}`
            }
          },
          policies: []
        }
    
        try {
            const url = `https://gator.edkek.com/popup?origin=${window.location.origin}`; // URL to open in the popup
            const windowName = 'Gator Wallet'; // Name of the popup window
            const windowFeatures = 'width=357,height=600'; // Size of the popup window
    
            const win = window.open(url, windowName, windowFeatures);
    
            if (win) {
              const checkPopupWindow = () => {
                try {
                  console.log(`Checking popup ${win.origin}`);
                  setTimeout(checkPopupWindow, 100);
                  return;
                } catch {
                  console.log(`Seems cross origin is active`)
                  const transport = wrapWindowAsTransport(win, "https://gator.edkek.com");
                  const popup = new BaseWindowClient({ transport });
    
                  setTimeout(async () => {
                    const response2 = await popup.sendMessage<WalletPermissionRequest, PermissionResponse>("wallet_grantPermissions", request)
                    console.log("Got response")
                    console.log(JSON.stringify(response2))
                    if (response2.address) {
                        setConnectedAddress(response2.address)
                        await Swal.fire("Success", "Wallet successfully authenticated", "success");
                    }
                    win.close();
                  })
                }
              };
    
              setTimeout(checkPopupWindow, 100);
            } else {
              await Swal.fire("Could not open popup", "Please allow pop ups", "warning");
            }
        } catch (e: any) {
          console.error(e);
          await Swal.fire("Error", `There was an error ${e?.message ? `:${e.message}` : ""}`, "error")
        }
      }, []);

    return (
        <MetaMaskPortfolioWalletContext.Provider
            value={{
                remoteLogin,
                sendWallet,
                isConnected: connectedAddress !== null,
                connectedAddress,
            }}
        >
            <>
                <StyledIFrame ref={iframeRef} src={IFrameUrl(window.location.origin)} allow="publickey-credentials-create *; publickey-credentials-get *" />
                {children}
            </>
        </MetaMaskPortfolioWalletContext.Provider>
    )
}