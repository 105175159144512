import React from "react";
import useIFrameCommunication, { JsonRpcJSError } from "../../comms/useIFrameCommunication";
import { DeleGatorClient } from "@codefi/delegator-core-viem";
import Swal from "sweetalert2";
import { GatorWebAuthnAccount, loadGatorPasskeyAccount, newGatorPasskeyAccount } from "../../gator";
import { sepolia as chain } from "viem/chains";
import { Button } from "@mui/material";

type Input = {
    hello: "world";
}

type Output = {
    success: boolean;
}

function passkeyError(e: any) {
    if ("cause" in e) {
        const err = e as Error;
        if (err.cause) {
            const causedError = err.cause as Error;
            const message = causedError.message;

            if (message === "The document is not focused." || message === "CredentialContainer request is not allowed.") {
                return new JsonRpcJSError(-32004, "Method not supported")
            }
        }
    }

    if ("message" in e) {
        const err = e as Error;
        if (err.message && err.message.trim() !== "") {
            return new JsonRpcJSError(-32603, err.message);
        }
    }

    return new JsonRpcJSError(-32600, JSON.stringify(e));
}

export default function Headless() {
    const { connected, handleMessage, windowReady, initWindow } = useIFrameCommunication();
    const messageSetup = React.useRef(false);
    const [delegator, setDelegator] = React.useState<DeleGatorClient>();
    const [account, setAccount] = React.useState<GatorWebAuthnAccount>();
    const [isError, setIsError] = React.useState(false);

    const createWallet = React.useCallback(async () => {
        try {
            const accountName = "Gator Web Wallet";
        
        
            const gatorAccount = await newGatorPasskeyAccount({
                walletName: accountName,
                rp: window.location.host,
                chain,
            });
        
            setDelegator(gatorAccount.delegator);
            setAccount(gatorAccount);
            return true;
        } catch (e: any) {
            throw passkeyError(e);
        }
      }, [setDelegator, setAccount, setIsError]);

    const loginWallet = React.useCallback(async () => {
        try {
            const selectedAccount = "Gator Web Wallet";
            
            const gatorAccount = await loadGatorPasskeyAccount({
                walletName: selectedAccount,
                rp: window.location.host,
                chain,
            });

            setDelegator(gatorAccount.delegator);
            setAccount(gatorAccount);
            return true;
        } catch (e: any) {
            throw passkeyError(e)
        }
    }, [setDelegator, setAccount]);

    React.useEffect(() => {
        if (!windowReady()) {
            initWindow(window.parent);
        }
    }, [windowReady])

    React.useEffect(() => {
        if (connected() && messageSetup.current) return;
        if (!connected()) return;

        handleMessage<Input, Output>("test", async (input) => {
            if (input.hello !== "world") {
                throw new Error("Invalid input");
            }
            const success = await createWallet();

            return {
                success,
            }
        })

        handleMessage<Input, Output>("test2", async (input) => {
            if (input.hello !== "world") {
                throw new Error("Invalid input");
            }
            const success = await loginWallet();

            return {
                success,
            }
        })
    }, [connected, handleMessage])

    return (
        <>
            <p style={{display: "none"}}>Connected?: {"" + connected()}</p>
        </>
    )
}