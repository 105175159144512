import { PasskeyStorage } from "./types";

export function buildLocalStorage(): PasskeyStorage {
    if (!localStorage && !globalThis.localStorage) {
        throw new Error("No localstorage API to use");
    }

    const _ls = localStorage || globalThis.localStorage;

    const saveKeyCache = (keys: Set<string>) => {
        let keyCacheJSON = JSON.stringify(Array.from(keys.values()));

        _ls.setItem("keys", keyCacheJSON);
    }

    const initKeyCache = () => {
        saveKeyCache(new Set());
    }

    const loadKeyCache = () => {
        let keyCacheJSON = _ls.getItem("keys");
        if (!keyCacheJSON) {
            initKeyCache();
            keyCacheJSON = "[]";
        }

        const keyCache = JSON.parse(keyCacheJSON) as string[];

        return new Set(keyCache);
    }

    const saveKey = <T>(key: string, val: T) => {
        const keys = loadKeyCache();

        if (!keys.has(key)) {
            keys.add(key);

            saveKeyCache(keys);
        }

        _ls.setItem(key, JSON.stringify(val));
    }

    const loadKey = <T>(key: string, def: T | null) => {
        const keyJson = _ls.getItem(key);
        if (!keyJson) {
            return def;
        }

        return JSON.parse(keyJson) as T;
    }

    return {
        writeData: <T>(key: string, val: T) => {
            saveKey(key, val);

            return Promise.resolve(true);
        },

        load: <T>(key: string): Promise<T | null> => {
            return Promise.resolve(loadKey<T>(key, null));
        },

        allKeys: (): string[] => {
            return Array.from(loadKeyCache());
        }
    }
}