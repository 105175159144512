import React from "react";
import useIFrameCommunication, { JsonRpcJSError } from "../../comms/useIFrameCommunication";
import { DeleGatorClient } from "@codefi/delegator-core-viem";
import Swal from "sweetalert2";
import { GatorWebAuthnAccount, loadGatorPasskeyAccount, newGatorPasskeyAccount } from "../../gator";
import { sepolia as chain } from "viem/chains";
import { Button, Divider, styled } from "@mui/material";
import "./onboard.css";

type Input = {
    hello: "world";
}

type Output = {
    success: boolean;
}

const Container = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    justify-content: center;
    align-items: center;

    button {
        max-width: 42%;
    }
`;

function passkeyError(e: any) {
    if ("cause" in e) {
        const err = e as Error;
        if (err.cause) {
            const causedError = err.cause as Error;
            const message = causedError.message;

            if (message === "The document is not focused." || message === "CredentialContainer request is not allowed.") {
                return new JsonRpcJSError(-32004, "Method not supported")
            }
        }
    }

    if ("message" in e) {
        const err = e as Error;
        if (err.message && err.message.trim() !== "") {
            return new JsonRpcJSError(-32603, err.message);
        }
    }

    return new JsonRpcJSError(-32600, JSON.stringify(e));
}

export default function Popup() {
    const { connected, handleMessage, windowReady, initWindow } = useIFrameCommunication();
    const messageSetup = React.useRef(false);
    const [delegator, setDelegator] = React.useState<DeleGatorClient>();
    const [account, setAccount] = React.useState<GatorWebAuthnAccount>();
    const [isError, setIsError] = React.useState(false);
    const createOrLoginPromise = React.useRef<((result: boolean) => void) | null>(null);

    const createWallet = React.useCallback(async () => {
        try {
            const accountName = "Gator Web Wallet";
        
        
            const gatorAccount = await newGatorPasskeyAccount({
                walletName: accountName,
                rp: window.location.host,
                chain,
            });
        
            setDelegator(gatorAccount.delegator);
            setAccount(gatorAccount);

            if (createOrLoginPromise.current) {
                createOrLoginPromise.current(true);
            }
            return true;
        } catch (e: any) {
            const err = passkeyError(e);

            await Swal.fire("Wallet Creation Failed", `There was an error creating the wallet: ${err.code} ${err.message}`, "error");
        }
      }, [setDelegator, setAccount, setIsError]);

    const loginWallet = React.useCallback(async () => {
        try {
            const selectedAccount = "Gator Web Wallet";
            
            const gatorAccount = await loadGatorPasskeyAccount({
                walletName: selectedAccount,
                rp: window.location.host,
                chain,
            });

            setDelegator(gatorAccount.delegator);
            setAccount(gatorAccount);

            if (createOrLoginPromise.current) {
                createOrLoginPromise.current(true);
            }
            return true;
        } catch (e: any) {
            const err = passkeyError(e)
            await Swal.fire("Wallet Authentication Failed", `There was an error authenticating the wallet: ${err.code} ${err.message}`, "error");
        }
    }, [setDelegator, setAccount]);

    React.useEffect(() => {
        if (!windowReady()) {
            if (window.document.readyState === "complete") {
                console.dir(window.opener);
                initWindow(window.opener);
            } else {
                window.addEventListener("load", () => {
                    console.dir(window.opener);
                    initWindow(window.opener);
                })
            }
        }
    }, [windowReady])

    React.useEffect(() => {
        if (connected() && messageSetup.current) return;
        if (!connected()) return;

        handleMessage<Input, Output>("test", async (input) => {
            console.log("Got test request")
            if (input.hello !== "world") {
                throw new Error("Invalid input");
            }

            console.log("Made create promise")
            const createWalletPromise = new Promise<boolean>((resolve, reject) => {
                createOrLoginPromise.current = resolve
            });

            console.log("Now we wait")
            const success = await createWalletPromise;

            return {
                success,
            }
        })

        handleMessage<Input, Output>("test2", async (input) => {
            console.log("Got test2 request")
            if (input.hello !== "world") {
                throw new Error("Invalid input");
            }

            console.log("Made login promise")
            const loginWalletPromise = new Promise<boolean>((resolve, reject) => {
                createOrLoginPromise.current = resolve
            })

            console.log("Now we wait")
            const success = await loginWalletPromise;

            return {
                success,
            }
        })

        messageSetup.current = true;
    }, [connected, handleMessage, createOrLoginPromise])

    return (
        <Container>
            <span className="inline-gator">
                🐊
            </span>
            <h2 style={{color: "white"}}>Login or Create Wallet</h2>
            <Divider />
            <Button variant="contained" onClick={createWallet}>Create New Wallet</Button>
            <Button variant="contained" onClick={loginWallet}>Login</Button>
        </Container>
    )
}