import { CanBeArray, JsonRpcMessage, JsonRpcResponse, JsonRpcRequest } from "./types";

const BigIntFormatter = (key: string, value: any) => typeof value === "bigint" ? value.toString() : value

export function formatJsonRPC<T>(method: string, val: CanBeArray<T>, id: string) {
    if (Array.isArray(val)) {
        return JSON.stringify({
            jsonrpc: "2.0",
            id,
            method,
            params: [...val]
        }, BigIntFormatter)
    }

    return JSON.stringify({
        jsonrpc: "2.0",
        method,
        id,
        params: [val]
    }, BigIntFormatter);
}

export function getRandomInt(max = 1337000) {
    return Math.floor(Math.random() * max);
  }

export function isJsonRpcResponse<R>(message: JsonRpcMessage<unknown, R>): message is JsonRpcResponse<R> {
    return "result" in message || "error" in message;
}

export function isJsonRpcRequest<T>(message: JsonRpcMessage<T, unknown>): message is JsonRpcRequest<T> {
    return "params" in message && "method" in message;
}

export class JsonRpcJSError extends Error {
    constructor(public code: number, message: string) {
        super(message)
    }
}