import { RouteObject } from "react-router-dom";
import App from "./root/App";
import CreateWallet from "./iframe/CreateWallet";
import Headless from "./iframe/Headless";
import Popup from "./iframe/OnBoardingPopup";

const Routes: RouteObject[] = [
    {
        path: "/",
        element: (<App />),
    },
    {
        path: "/iframe/create",
        element: <CreateWallet />,
    },
    {
        path: "/iframe/headless",
        element: <Headless />,
    },
    {
        path: "/iframe/onboard",
        element: <Popup />,
    }
];

export default Routes;